
import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "pricing",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("General", "Pricing");
    });
  },
});
